<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";

export default {
    components: {
        AdvancedTable,
    },

    computed: {
        fields: function () {
            return [
                {
                    key: "menu",
                    label: this.$t('menus::menu_point_categories.relationships.menu').ucFirst(),
                    formatter: (value) => {
                        return value ? (value.translation_key ? this.$t(value.translation_key).ucFirst() : value.name) : null
                    },
                    thClass: '',
                    sortable: true
                },
                {
                    key: "name",
                    label: this.$t('menus::menu_point_categories.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "icon",
                    label: this.$t('menus::menu_point_categories.columns.icon').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "sort",
                    label: this.$t('menus::menu_point_categories.columns.sort').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "authentication_required",
                    label: this.$t('menus::menu_point_categories.columns.authentication_required').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'menus::menu_point_categories.menu_point_category', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('menus::menu_point_categories.menu_point_category', 2).ucFirst()"
        :create-link="{ name: 'menu-point-category-create' }"
        :current-page="1"
        :edit-link="{ name: 'menu-point-category-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        moduleNamespace="menuPointCategory"
        outlined
        permission="menu_point_categories"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
    >
        <template #menu="{ value }">
            <i v-if="!value" class="fas fa-ban opacity-50"></i>
        </template>
        <template #icon="{ value }">
            <i v-if="value" :class="value" class="fa"></i>
        </template>
        <template #authentication_required="{ value }">
            <i v-if="value === true" class="fas fa-shield-alt text-success"></i>
            <i v-else class="fas fa-lock-open text-warning opacity-50"></i>
        </template>
    </advanced-table>
</template>
